<template>
  <v-container fluid>
    <v-row>
      <v-col :cols="4">
        <base-material-card color="drawerColorSecondary">
          <template v-slot:heading>
            <div class="display-2 font-weight-medium">Capturar Datos</div>
          </template>
          <v-text-field
            v-model="idTutor"
            :counter="15"
            label="ID"
            required
          >
          </v-text-field>
          <v-text-field
            v-model="nombre"
            :counter="100"
            label="Nombre(s)"
            required
            @input="nombre = nombre.toUpperCase()"
          >
          </v-text-field>
          <v-text-field
            v-model="apellidos"
            :counter="100"
            label="Apellidos"
            required
            @input="apellidos = apellidos.toUpperCase()"
          >
          </v-text-field>
          <v-text-field
            v-model="correo"
            :counter="50"
            label="Correo"
            required
            @input="correo = correo.toLowerCase()"
          >
          </v-text-field>
          <v-btn small @click="dialog = true">Registrar Tutor</v-btn>
          <v-btn small @click="limpiarCampos()">Limpiar Campos</v-btn>
        </base-material-card>
      </v-col>

      <v-col :cols="8">
        <base-material-card color="drawerColorSecondary">
          <template v-slot:heading>
            <div class="display-2 font-weight-medium">Tutores</div>
          </template>
          <v-spacer></v-spacer>
          <v-col cols="4" class="text-right">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
            ></v-text-field>
          </v-col>
        </base-material-card>
        <v-data-table
          :headers="headers"
          :items="tutores"
          :search="search"
          :items-per-page="5"
          dense
        >
        </v-data-table>

        <v-col cols="12" class="text-right">
            <v-dialog v-model="dialog" persistent max-width="340">
              <v-card>
                <v-card-title class="headline"
                  >¿Dar de alta el material?</v-card-title
                >
                <v-card-text
                  >Al dar aceptar se harán los cambios en el
                  servidor.</v-card-text
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="dialog = false"
                    >Cancelar</v-btn
                  >
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="
                      agregarTutor();
                      limpiarCampos();
                      dialog = false;
                    "
                    >Aceptar</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "EditarTutores",
  data: () => ({
    search: "",
    headers: [
      { text: "ID", value: "idTutor" },
      { text: "Nombre(s)", value: "nombre" },
      { text: "Apellidos", value: "apellidos" },
      { text: "Correo Electrónico", value: "correo" },
    ],
    tutores: [],
    idTutor: "",
    nombre: "",
    apellidos: "",
    correo: "",
    carrera: "",
    dialog: false,
  }),
  mounted () {
    fetch(process.env.VUE_APP_URL_BACKEND + '/administrador/tutores', {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        Authorization: "Bearer " + this.$store.state.cuenta.token,
        }
      }).then(response => {
        return response.json();
      }).then(tutores => {
        this.tutores = tutores;
      });
  },
  methods: {
    agregarTutor() {
       fetch(process.env.VUE_APP_URL_BACKEND + "/administrador/tutores", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.$store.state.cuenta.token,
          },
          body: JSON.stringify({
            idTutor: this.idTutor,
            nombre: this.nombre,
            apellidos: this.apellidos,
            correo: this.correo,
          })
        })
          .then(response => {
            return response.json();
          })
          .then(data => {
            this.tutores.push(data);
          });
      //}
    },
    limpiarCampos() {
      this.nombre = "";
      this.idTutor = "";
      this.apellidos = "";
      this.correo = "";
      this.carrera = "";
    },
  }
};
</script>
